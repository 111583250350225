.windpanel {
  display: grid;
  justify-content: start;
  grid-template-columns: auto auto auto auto auto auto;
  font-family: monospace;
  border: 1px solid black;
  width: fit-content;
  padding: 5px 5px 5px 5px; }

.windpanel-evenrow {
  background: #e8e8e8; }

.windpanel-ok {
  color: #00cc00; }

.windpanel-loading {
  color: #888888; }

.windpanel-stale {
  color: #B37208; }

.windpanel-error {
  color: #FF0000; }

.windpanel-header {
  font-weight: bold; }

.windpanel-rule {
  grid-column: 1 / span 6;
  background: black;
  height: 1px;
  margin-top: 2px;
  margin-bottom: 2px; }

.windpanel-station {
  text-align: left;
  padding-left: 5px; }

.windpanel-dir {
  text-align: right;
  padding-left: 10px; }

.windpanel-speed {
  text-align: right;
  padding-left: 10px; }

.windpanel-gust {
  text-align: right;
  padding-left: 10px; }

.windpanel-age {
  text-align: right;
  padding-left: 10px; }

.windpanel-status {
  padding-left: 10px;
  padding-right: 5px; }
